import { Injectable } from '@angular/core';
import { DataFetchService } from '@app/shared/services/data-fetch.service';
import { HttpClient } from '@angular/common/http';
import { ProjectConfigService } from '@app/shared/services/project-config.service';
import * as moment from 'moment/moment';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { Occupation } from '@app/shared/models/occupation';
import { Country } from '@app/shared/models/country';
import { CountryDialCode } from '@app/shared/models/country-dial-code';
import { newUserResponse } from '@app/shared/models/new-user-response';
import { ErrorResponse } from '@app/shared/models/error-response';
import { CheckSmsCodeResponse, SendSmsCodeErrorResponse, SendSmsCodeResponse } from '@app/shared/models/phone-number';
import { updateUserResponse } from '@app/shared/models/update-user-response';
import { CheckPayladoRegistrationPayload } from '@app/shared/models/check-paylado';
import { DetectGenderPayload, DetectGenderResponse } from '@app/shared/models/detect-gender';
import { Gender } from '@app/shared/models/gender';
import { LoqateService } from './loqate.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService extends DataFetchService {
  countryList$: Country[] = [];
  countryList = new Subject<Country[]>();

  occupationList$: Occupation[] = [];
  occupationList = new Subject<Occupation[]>();

  dialCodeList$: CountryDialCode[] = [];
  dialCodeList = new Subject<CountryDialCode[]>();

  genderList$: Gender[] = [];
  genderList = new Subject<Gender[]>();

  private hasSonioReadFullAddressSubject = new BehaviorSubject<boolean>(false);
  hasSonioReadFullAddress$ = this.hasSonioReadFullAddressSubject.asObservable();

  updateSonioAddressStatus(status: boolean): void {
    this.hasSonioReadFullAddressSubject.next(status);
  }

  constructor(public http: HttpClient, public config: ProjectConfigService, public loqateService: LoqateService) {
    super(http, config);
    this.getCountries();
    this.getOccupations();
    this.getCountryDialCodes();
  }

  getCountries(): void {
    // this.post(`${this.environment.hostUrl}/${this.environment.getCountries}`, {}).subscribe(countries => {
    this.post(`${this.environment.hostUrlDolphin}/${this.environment.getCountriesDolphin}`, {}).subscribe(countries => {
      // map data for select input
      for (const iso3 of Object.keys(countries)) {
        this.countryList$.push({
          key: iso3,
          value: countries[iso3]
        });
      }

      const replaceIndex = this.countryList$.findIndex(el => el.key == 'GBR');
      const elementToPush = this.countryList$[replaceIndex];
      this.countryList$.splice(replaceIndex, 1);
      this.countryList$.splice(1, 0, elementToPush);

      this.countryList.next([...this.countryList$]);
    });
  }

  getOccupations(): void {
    // this.post<Occupation[]>(`${this.environment.hostUrl}/${this.environment.getOccupations}`, {}).subscribe(
    this.post<Occupation[]>(
      `${this.environment.hostUrlDolphin}/${this.environment.getOccupationsDolphin}`,
      {}
    ).subscribe(occupations => {
      this.occupationList$ = [...occupations];
      this.occupationList.next([...this.occupationList$]);
    });
  }

  getCountryDialCodes(): void {
    // this.post<CountryDialCode[]>(`${this.environment.hostUrl}/${this.environment.getCountriesDialCodes}`, {}).subscribe(
    this.post<CountryDialCode[]>(
      `${this.environment.hostUrlDolphin}/${this.environment.getCountriesDialCodesDolphin}`,
      {}
    ).subscribe(dialCodes => {
      const replaceIndex = dialCodes.findIndex(el => el.key == 'gb');
      const elementToPush = dialCodes[replaceIndex];
      dialCodes.splice(replaceIndex, 1);
      dialCodes.splice(1, 0, elementToPush);
      this.dialCodeList$ = [...dialCodes];
      this.dialCodeList.next([...this.dialCodeList$]);
    });
  }

  getGenders(): void {
    this.post<Gender[]>(`${this.environment.hostUrlDolphin}/${this.environment.getGendersDolphin}`, {}).subscribe(
      genders => {
        this.genderList$ = [...genders];
        this.genderList.next([...this.genderList$]);
      }
    );
  }

  createUser(payload = {}): Observable<any> {
    return this.post<newUserResponse | ErrorResponse>(
      // `${this.environment.hostUrl}/${this.environment.saveCustomer}`,
      `${this.environment.hostUrlDolphin}/${this.environment.saveCustomerDolphin}`,
      this.prepareRegistrationData(payload)
    );
  }

  updateUser(payload = {}, id: string): Observable<any> {
    return this.patch<updateUserResponse | ErrorResponse>(
      // `${this.environment.hostUrl}/${this.environment.updateCustomer}/${id}`,
      `${this.environment.hostUrlDolphin}/${this.environment.updateCustomerDolphin}/${id}`,
      this.prepareUpdateData(payload)
    );
  }

  validateEmail(payload = {}): Observable<any> {
    return this.post<boolean>(
      // `${this.environment.hostUrl}/${this.environment.customerValidateEmail}`,
      `${this.environment.hostUrlDolphin}/${this.environment.customerValidateEmailDolphin}`,
      this.prepareValidateEmailData(payload)
    );
  }

  addPaylado(payload = {}): Observable<any> {
    return this.post<newUserResponse | ErrorResponse>(
      `${this.environment.hostUrlDolphin}/${this.environment.createPayladoAccountDolphin}`,
      payload
    );
  }

  checkPayladoRegistration(payload: CheckPayladoRegistrationPayload): Observable<boolean> {
    return this.post<boolean>(`${this.environment.hostUrl}/${this.environment.checkPayladoRegistration}`, payload);
  }

  detectGender(payload: DetectGenderPayload): Observable<DetectGenderResponse> {
    return this.post<DetectGenderResponse>(`${this.environment.hostUrl}/${this.environment.detectGender}`, payload);
  }

  calculateAddressSource(formData: any): string {
    const { street, house_number, zip_code, city, country } = formData;

    const hasSonioReadFullAddress = this.hasSonioReadFullAddressSubject.getValue();
    let addressSource = 'Manual';

    if (hasSonioReadFullAddress) {
      addressSource = 'ID-Card';
    } else {
      const loqateAddress = this.loqateService.addressDetails.getValue();

      if (loqateAddress) {
        const isViaLoqate =
          loqateAddress.street === street &&
          loqateAddress.house_number === house_number &&
          loqateAddress.zip_code === zip_code &&
          loqateAddress.city === city &&
          loqateAddress.country === country;

        if (isViaLoqate) {
          addressSource = 'Loqate';
        }
      }
    }

    return addressSource;
  }

  prepareRegistrationData(formData: any): { [key: string]: unknown } {
    const address_source = this.calculateAddressSource(formData.stepThree.address);
    return {
      // user data from form step one
      newsletter_agb: +formData.stepOne.agb,
      phone_number: this.formatPhoneNumber(formData.stepOne.phone_number),
      email: formData.stepOne.email,
      password: formData.stepOne.password,
      // user data from step two
      first_name: formData.stepThree.first_name,
      last_name: formData.stepThree.last_name,
      birth_name: formData.stepThree.birth_name,
      street: formData.stepThree.address.street,
      house_number: formData.stepThree.address.house_number,
      zip_code: formData.stepThree.address.zip_code,
      city: formData.stepThree.address.city,
      country: formData.stepThree.address.country, // country code. format: iso alpha 3
      birth_date: moment(formData.stepThree.birth_date).format('YYYY-MM-DD'), // format: yyyy-mm-dd
      place_of_birth: formData.stepThree.place_of_birth,
      country_of_birth: formData.stepThree.country_of_birth, // country code. format: iso alpha 3
      citizenship: formData.stepThree.citizenship,
      occupation: formData.stepThree.occupation,
      gender: formData.stepThree.gender,
      auto_ident: formData.stepThree.auto_ident,
      address_source: address_source
    };
  }

  prepareUpdateData(formData: any): { [key: string]: unknown } {
    const address_source = this.calculateAddressSource(formData);

    return {
      email: formData.email,
      newsletter_agb: +formData.newsletter_agb,
      password: formData.password,
      phone_number: formData.phone_number,
      first_name: formData.first_name,
      last_name: formData.last_name,
      birth_name: formData.birth_name,
      street: formData.address.street,
      house_number: formData.address.house_number,
      zip_code: formData.address.zip_code,
      city: formData.address.city,
      country: formData.address.country, // country code. format: iso alpha 3
      birth_date: formData.birth_date, // format: yyyy-mm-dd
      place_of_birth: formData.place_of_birth,
      country_of_birth: formData.country_of_birth, // country code. format: iso alpha 3
      citizenship: formData.citizenship,
      occupation: formData.occupation,
      gender: formData.gender,
      auto_ident: formData.auto_ident,
      address_source: address_source
    };
  }

  prepareValidateEmailData(formData: any): { [key: string]: unknown } {
    return {
      newsletter_agb: +formData.agb,
      phone_number: this.formatPhoneNumber(formData.phone_number),
      email: formData.email,
      password: formData.password
    };
  }

  /**
   * Concat dial code with the base phone number.
   *
   * @returns formatted phone number
   */
  formatPhoneNumber(formData: { number: string; dialCode: string }): string {
    try {
      const phoneNumber = formData.number;
      if (phoneNumber[0] === '0') {
        formData.number = phoneNumber.slice(1);
      }

      return '+' + formData.dialCode + formData.number;
    } catch (_) {
      return '';
    }
  }

  sendSmsCode(data): Observable<SendSmsCodeResponse> {
    const payload = {
      phoneNumber: data.phoneNumber
    };
    return this.post<SendSmsCodeResponse | SendSmsCodeErrorResponse>(
      `${this.environment.octopusApiUrl}/${this.environment.sendSmsVerificationCode}`,
      payload
    );
  }

  checkSmsCode(data): Observable<CheckSmsCodeResponse> {
    const payload = {
      phoneNumber: data.phoneNumber,
      verificationCode: data.verificationCode
    };

    return this.post<CheckSmsCodeResponse | SendSmsCodeErrorResponse>(
      `${this.environment.octopusApiUrl}/${this.environment.checkSmsVerificationCode}`,
      payload
    );
  }
}
